/* Main application styling */
.App {
  text-align: center;
}

/* Styling for fixed table layouts and animations */
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

thead th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 10;
}

th,
td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

/* Column specific widths */
th:nth-child(1),
td:nth-child(1) {
  width: 15%;
}
th:nth-child(2),
td:nth-child(2) {
  width: 15%;
}
th:nth-child(3),
td:nth-child(3) {
  width: 30%;
}
th:nth-child(4),
td:nth-child(4) {
  width: 30%;
}
th:nth-child(5),
td:nth-child(5) {
  width: 10%;
}

/* Animation for row updates */
@keyframes rowFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

tbody tr {
  animation: rowFadeIn 0.5s ease-out;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it covers other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Higher than backdrop to ensure it appears on top */
}

/* Button and select styling */
button,
select {
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: gray;
}

textarea {
  width: 100%; /* Full-width of modal */
  margin-bottom: 10px;
}

.updated-label {
  background-color: #1a73e8;
  color: white;
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.link-button {
  background: none;
  color: #1a73e8;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:hover {
  color: #fff;
}

.cancel-button {
  background-color: #ff4c4c;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.cancel-button:hover {
  background-color: #ff0000;
}
