body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  width: 100%; /* Ensures the table takes full width of its container */
  border-collapse: collapse; /* Removes spacing between borders of table cells */
}

th,
td {
  padding: 12px 15px; /* Increase padding to add space within cells */
  vertical-align: top; /* Aligns text to the top of the cell */
  border-bottom: 1px solid #ddd; /* Adds a bottom border to each cell for clarity */
}

tr:hover {
  background-color: #f5f5f5; /* Optional: adds a hover effect on rows */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
